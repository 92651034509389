html,
body,
#root {
    height: 100%;
}

a {
    color: inherit;
}

.Toastify__toast-body {
    font-family: "Poppins", sans-serif;
}
